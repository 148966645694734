/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosRequestConfig } from 'axios';
import AdvancedFilter from 'models/AdvancedFilter';
import { BundleDealES, BundleDealStatus } from 'models/BundleDeal';
import { SearchCustomerES } from 'models/Customer';
import { PaginationPayload } from 'models/PaginationPayload';
import Product, { ESProduct } from 'models/Product';
import ProductFilter from 'models/ProductFilter';
import UserSpace from 'models/UserSpace';
import { TOKEN_STORAGE_KEY, CURRENT_SHOP_STORAGE_KEY } from 'utils/constants';
import { getCartSortKey, getOrderSortKey } from 'utils/functions';
import { requestParamsFromObject } from 'utils/request';
import { fetchProductAfterLiveStreamById, fetchProductDuringLiveStreamById } from './products';
import { isEmpty } from 'lodash';

type ProductsRequestWithES = {
  pagination: PaginationPayload;
  filter: ProductFilter;
  isFetchSingleVariantAsNormal?: boolean;
  isFetchFlattenVariants?: boolean;
  capturingSessionId?: string;
  sessionType?: 'during' | 'after';
};

export type ProductResponseWithES = {
  data?: {
    data: Product[];
  };
  total?: number;
  rawDataFromES?: ESProduct[];
  productAndVariantCount?: number;
  searchAfter?: (number | string)[];
};

const BASE_SEARCH_URL = process.env.REACT_APP_API_BASE_SEARCH_URL;

const instance = Axios.create({
  baseURL: BASE_SEARCH_URL,
});

export const onRequestSuccess = async (config: AxiosRequestConfig) => {
  try {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    const currentUserSpace = localStorage.getItem(CURRENT_SHOP_STORAGE_KEY);

    if (config.headers) {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (currentUserSpace) {
        const parsedCurrentUserSpace: UserSpace = JSON.parse(currentUserSpace);
        config.headers.UserWorkspace = parsedCurrentUserSpace.ownerKey;
      }
    }
  } catch (error) {
    // Unauthenticated user, return default config
  }

  return config;
};
instance.interceptors.request.use(onRequestSuccess);

export const getCartIds =
  (filter: AdvancedFilter = {}, pageNumber?: number, pageSize?: number, orderBy?: string, orderDirection?: string) =>
  async () => {
    const paramsObject = {
      ...(!pageNumber ? {} : { _page: pageNumber }),
      ...(!pageSize ? {} : { _limit: pageSize }),
      ...(!orderBy ? {} : { _sort_key: getCartSortKey(orderBy) }),
      ...(!orderDirection ? {} : { _sort_direction: orderDirection === 'ascend' ? 'asc' : 'desc' }),
    };
    const res = await instance.post(`/carts${requestParamsFromObject(paramsObject)}`, filter);
    return res;
  };

export const getOrderIds =
  (filter: AdvancedFilter = {}, pageNumber?: number, pageSize?: number, orderBy?: string, orderDirection?: string) =>
  async () => {
    const paramsObject = {
      ...(!pageNumber ? {} : { _page: pageNumber }),
      ...(!pageSize ? {} : { _limit: pageSize }),
      ...(!orderBy ? {} : { _sort_key: getOrderSortKey(orderBy) }),
      ...(!orderDirection ? {} : { _sort_direction: orderDirection === 'ascend' ? 'asc' : 'desc' }),
    };
    const res = await instance.post(`/orders${requestParamsFromObject(paramsObject)}`, filter);
    return res;
  };

export const getProductIds = (pagination: PaginationPayload, filter: ProductFilter) => async () => {
  const res = await instance.post<{ data: ESProduct[] }>(
    `/products${requestParamsFromObject({ ...pagination })}`,
    filter
  );
  return res;
};

export const countProductIds = (pagination: PaginationPayload, filter: ProductFilter) => async () => {
  const res = await instance.post(`/products/count${requestParamsFromObject({ ...pagination })}`, filter);
  return res;
};

export const countCartIds =
  (filter: AdvancedFilter = {}) =>
  async () => {
    const res = await instance.post(`/carts/count`, filter);
    return res?.data?.count;
  };

export const countOrderIds =
  (filter: AdvancedFilter = {}) =>
  async () => {
    const res = await instance.post(`/orders/count`, filter);
    return res?.data?.count;
  };

export const getFilterOptions = (_name: string, _limit: number, keyword: string, _page?: number) => async () => {
  const paramsObject = {
    ...(!_name ? {} : { _name }),
    ...(!_limit ? {} : { _limit }),
    ...(!keyword ? {} : { keyword }),
    ...(!_page ? {} : { _page }),
  };
  const res = await instance.post(`/options${requestParamsFromObject(paramsObject)}`);
  return res?.data;
};

export const getProductWithES =
  ({ pagination, filter, isFetchSingleVariantAsNormal, isFetchFlattenVariants }: ProductsRequestWithES) =>
  async () => {
    let rawData, total, productAndVariantCount;
    if (isFetchFlattenVariants) {
      const res = await instance.post<{ data: ESProduct[]; total: number }>(
        `/products/flatten${requestParamsFromObject({ ...pagination })}`,
        filter
      );
      rawData = res.data.data;
      total = res.data.total;
      productAndVariantCount = total;
    } else {
      const getProductList = instance.post<{ data: ESProduct[]; total: number }>(
        `/products${requestParamsFromObject({ ...pagination })}`,
        filter
      );
      const getCount = instance.post<{ count: number }>(
        `/products/count${requestParamsFromObject({ ...pagination })}`,
        filter
      );
      const values = await Promise.all([getProductList, getCount]);
      rawData = values[0].data.data;
      total = values[0].data.total;
      productAndVariantCount = values[1].data.count;
    }

    const productIds = rawData.map((item) => item.product_id);

    const singleVariantIds = rawData
      .filter((item) => item.variants?.length === 1)
      .map((item) => item.variants?.[0].product_id || '');

    const productWithoutSingleVariantIds = rawData
      .filter((item) => item.variants?.length !== 1)
      .map((item) => item.product_id);

    const productDetailRes = await Axios.post<{
      data: Product[];
    }>(`/product-service/api/products/search/ids`, {
      ids: isFetchSingleVariantAsNormal ? [...productWithoutSingleVariantIds, ...singleVariantIds] : productIds,
      ...(!filter.include_deleted ? {} : { includeDeleted: filter.include_deleted }),
    });

    return {
      data: productDetailRes.data,
      total,
      productAndVariantCount,
      rawDataFromES: rawData,
    };
  };

export const countProduct =
  ({ pagination, filter }: ProductsRequestWithES) =>
  async () => {
    const res = await instance.post<{ count: number }>(
      `/products/count${requestParamsFromObject({ ...pagination })}`,
      filter
    );

    return { productAndVariantCount: res.data.count };
  };

export const countProductInGroups = (groupIds?: string[]) => async () => {
  const listPromises =
    groupIds?.map((id) =>
      instance.post<{ count: number }>(`/products/count`, {
        product_group_ids: [id],
      })
    ) || [];

  const values = await Promise.all(listPromises);
  const formattedValue: { [key: string]: number } =
    groupIds?.reduce((acc, groupId, index) => {
      return {
        ...acc,
        [groupId]: values[index]?.data?.count || 0,
      };
    }, {}) || {};

  return formattedValue;
};

export const getCustomerIds =
  (
    search?: string,
    pageNumber?: number,
    pageSize?: number,
    order?: {
      orderBy: string;
      orderDirection: 'ascend' | 'descend';
    },
    customerTypes?: string[]
  ) =>
  async () => {
    const paramsObject = {
      ...(!pageNumber ? {} : { _page: pageNumber }),
      ...(!pageSize ? {} : { _limit: pageSize }),
      ...(!order?.orderBy ? {} : { _sort_key: order.orderBy }),
      ...(!order?.orderDirection ? {} : { _sort_direction: order.orderDirection === 'ascend' ? 'asc' : 'desc' }),
    };
    const filter = {
      ...(!search ? {} : { _keywords: search }),
      ...(isEmpty(customerTypes) ? {} : { customer_types: customerTypes }),
    };

    const res = await instance.post<{ data: SearchCustomerES[]; total: number }>(
      `/customers${requestParamsFromObject(paramsObject)}`,
      filter
    );
    return res.data;
  };

export const getBundleDeals =
  ({
    pageNumber,
    pageSize,
    searchTerm,
    status,
    isFullyRedeemed,
  }: {
    isFullyRedeemed?: boolean;
    status?: BundleDealStatus;
    searchTerm?: string | undefined;
    pageNumber: number;
    pageSize: number;
  }) =>
  async () => {
    const paramsObject = {
      ...(!pageNumber ? {} : { _page: pageNumber }),
      ...(!pageSize ? {} : { _limit: pageSize }),
      _sort_key: 'bundle_created_at',
      _sort_direction: 'desc',
    };
    const filter = {
      ...(!searchTerm ? {} : { _keywords: searchTerm }),
      ...(status === BundleDealStatus.All ? {} : { status }),
      ...(isFullyRedeemed ? { fully_redeemed: isFullyRedeemed } : {}),
    };

    const res = await instance.post<{ data: BundleDealES[]; total: number }>(
      `/bundles${requestParamsFromObject(paramsObject)}`,
      filter
    );

    return res.data;
  };

export const getProductWithESV2 =
  ({ pagination, filter }: ProductsRequestWithES) =>
  async () => {
    const getProductList = await instance.post<{ data: ESProduct[]; total: number }>(
      `/products${requestParamsFromObject({ ...pagination })}`,
      filter
    );

    return {
      data: getProductList.data.data,
      total: getProductList.data.total,
    };
  };

export const getProductWithESInfinite =
  ({ filter, isFetchSingleVariantAsNormal }: ProductsRequestWithES) =>
  async (key: string) => {
    const values = await instance.post<{ data: ESProduct[]; total: number }>(key, filter);

    const productIds = values.data.data.map((item) => item.product_id);

    const singleVariantIds = values.data.data
      .filter((item) => item.variants?.length === 1)
      .map((item) => item.variants?.[0].product_id || '');

    const productWithoutSingleVariantIds = values.data.data
      .filter((item) => item.variants?.length !== 1)
      .map((item) => item.product_id);

    const productDetailRes = await Axios.post<{
      data: Product[];
    }>(`/product-service/api/products/search/ids`, {
      ids: isFetchSingleVariantAsNormal ? [...productWithoutSingleVariantIds, ...singleVariantIds] : productIds,
    });

    return {
      data: productDetailRes.data,
      rawDataFromES: values.data.data,
    };
  };

export const getProductInLiveStream =
  ({ filter, isFetchSingleVariantAsNormal, capturingSessionId, sessionType }: ProductsRequestWithES) =>
  async (key: string) => {
    const values = await instance.post<{ data: ESProduct[]; total: number }>(key, filter);

    const productIds = values.data.data.map((item) => item.product_id);

    const singleVariantIds = values.data.data
      .filter((item) => item.variants?.length === 1)
      .map((item) => item.variants?.[0].product_id || '');

    const productWithoutSingleVariantIds = values.data.data
      .filter((item) => item.variants?.length !== 1)
      .map((item) => item.product_id);

    const ids = isFetchSingleVariantAsNormal ? [...productWithoutSingleVariantIds, ...singleVariantIds] : productIds;

    const productDetailRes =
      sessionType === 'during'
        ? await fetchProductDuringLiveStreamById(ids, capturingSessionId || '')()
        : await fetchProductAfterLiveStreamById(ids, capturingSessionId || '')();

    return {
      data: productDetailRes,
      rawDataFromES: values.data.data,
    };
  };

export const getProductWithESInfiniteByCursor =
  ({ pagination, filter, isFetchSingleVariantAsNormal }: ProductsRequestWithES) =>
  async (...key: (string | number)[]) => {
    const nextCursor = key?.slice(1) || [];

    const values = await instance.post<{ data: ESProduct[]; total: number; search_after?: (number | string)[] }>(
      `/products${requestParamsFromObject({ ...pagination, ...filter })}`,
      {
        ...(!isEmpty(nextCursor) ? { search_after: nextCursor } : {}),
        ...filter,
      }
    );

    const productIds = values.data.data.map((item) => item.product_id);

    const singleVariantIds = values.data.data
      .filter((item) => item.variants?.length === 1)
      .map((item) => item.variants?.[0].product_id || '');

    const productWithoutSingleVariantIds = values.data.data
      .filter((item) => item.variants?.length !== 1)
      .map((item) => item.product_id);

    const productDetailRes = await Axios.post<{
      data: Product[];
    }>(`/product-service/api/products/search/ids`, {
      ids: isFetchSingleVariantAsNormal ? [...productWithoutSingleVariantIds, ...singleVariantIds] : productIds,
    });

    return {
      data: productDetailRes.data,
      rawDataFromES: values.data.data,
      searchAfter: values.data.search_after,
    };
  };

export const getCartFilteredResult =
  (filter: AdvancedFilter = {}) =>
  async () => {
    const res = await instance.post<{ cart_total: number; cart_total_items: number }>('/carts/filter-result', filter);
    return { total: res.data.cart_total, items: res.data.cart_total_items };
  };
