import Post from './Post';
import { SocialPlatformType } from './PostDetailData';
import { CapturingSessionApplyOn } from './LiveSelling';
import Product from './Product';

export enum Status {
  Draft,
  Ongoing,
  Ended,
}

export const mappingStatusToName = (status?: Status) => {
  switch (status) {
    case Status.Draft:
      return 'draft';
    case Status.Ongoing:
      return 'ongoing';
    case Status.Ended:
      return 'ended';
    default:
      return '';
  }
};

export interface PostCapturingSession extends Post {
  capturingSessionId: string;
  socialPlatformType: SocialPlatformType;
  thumbnailUrl: string;
  sync: boolean;
  syncTime: string;
  permalinkUrl: string;
}

export interface CapturingSession {
  id: string;
  name: string;
  status: Status;
  created: string;
  post: PostCapturingSession;
  ordersCount: number;
  selectedProductsCount: number;
  subTotal: number;
  subTotalPaid: number;
  subTotalPending: number;
  productIds: string[];
  applyOn?: CapturingSessionApplyOn;
  firstCapturingCommentAt?: string;
  products?: Product[];
  soldQuantity?: number;
}

export interface TotalCount {
  totalCount: number;
  percentage: number;
}

export interface OverviewPerformance {
  capturingSession: TotalCount & {
    endedCount: number;
  };
  comment: TotalCount & {
    keywordCount: number;
  };
  order: TotalCount & {
    paidCount: number;
  };
  commenter: TotalCount & {
    bidderCount: number;
  };
  soldQuantity: TotalCount & {
    paidCount: number;
  };
  subTotal: {
    subTotal: number;
    subTotalPaid: number;
    subTotalPending: number;
    percentage: number;
  };
}

export interface OverviewAmountPerformance {
  soldQuantity: {
    totalCount?: number;
  };
  subTotal: {
    percentage: number;
    subTotal?: number;
    subTotalPaid?: number;
    subTotalPaidAfterBundle?: number;
    subTotalPending?: number;
  };
}
